'use strict';
//  Author: AdminDesigns.com
// 
//  This file is reserved for changes made by the user 
//  as it's often a good idea to seperate your work from 
//  the theme. It makes modifications, and future theme
//  updates much easier 
// 

//  Place custom scripts below this line 
///////////////////////////////////////


$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name=csrf-token]').attr('content')
    }
});

//set x-editable defaults
$.fn.editable.defaults.mode = 'inline';
$.fn.editable.defaults.emptytext = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
$.fn.editable.defaults.ajaxOptions = {type: "PUT"};

//set datepicker defaults
$.fn.datepicker.defaults.autoclose = true;
$.fn.datepicker.defaults.todayHighlight = true;
$.fn.datepicker.defaults.weekStart = 1;
$.fn.datepicker.defaults.daysOfWeekDisabled = '0,6';
$.fn.datepicker.defaults.format = 'dd.mm.yyyy';

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "0",
    "extendedTimeOut": "0",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

$('body').on('click', function (e) {
    if (!$('li.notification-entry').is(e.target)
        && $('li.notification-entry').has(e.target).length === 0
        && $('.open').has(e.target).length === 0
    ) {
        $('#notifications-menu').removeClass('open');
    }
});

Notification.requestPermission(function (permission) {
    // get permission
});

var pusher = new Pusher('463fe3a5b19cd2f4208b', {
    encrypted: true
});

var channel = pusher.subscribe('user-' + $('meta[name=current-user]').attr('content'));
channel.bind('new-notification', function (data) {
    toastr['info'](data.description, data.name);
    Notification.requestPermission(function (permission) {
        var notification = new Notification(data.name, {
            body: data.description,
            icon: '/img/notification_logo.png',
            dir: 'auto',
            requireInteraction: true
        });
        /*setTimeout(function(){
         notification.close();
         },15000);*/
        var link = 'http://keevo.io/network/' + data.network_id + '/' + data.type + '/' + data.item_id;
        notification.onclick = function () {
            window.location.href = link;
        };

    });
    actionAjax("GET", '/notifications/entries/count', null, '.notifications-count');
});

$(document).on('click', '#notifications-menu .recent-notifications-btn', function(e) {
    if(!$(this).parent().hasClass('open')) {
        actionAjax("GET", '/notifications/entries', null, '.notifications-entries');
    }
    $(this).parent().toggleClass('open');
});

$(document).on('click', '.notification-entry', function (e) {
    e.preventDefault();
    var data = {id: $(this).data('notification'), status: '1'};
    actionAjax("PUT", '/notifications/status', data, null);
    $(this).fadeOut();
    actionAjax("GET", '/notifications/entries/count', null, '.notifications-count');
});

$(document).on('click', '.notification-mark-all', function (e) {
    e.preventDefault();
    actionAjax("PUT", '/notifications/mark-all', null, null);
    $('.notification-entry').fadeOut();
    actionAjax("GET", '/notifications/entries/count', null, '.notifications-count');
});

$(document).ready(function () {

    const directLinkCheck = window.location.href.match(/(network)(\/.*)(\/.*)(\/.*)/);

    if (directLinkCheck !== null && directLinkCheck.length > 3) {
        let type = directLinkCheck[3].substring(1);
        if (type !== 'client' && type !== 'project') {
            Bus.$emit('openSidebarPanel', type, directLinkCheck[4].substring(1));
        }
    }

    loadEditable();
    $(window).resize();


    /*
     * Search global
     */

    $('#header-search').on('focus', function () {
        $(this).val('');
        $(this).autocomplete({
            minChars: 2,
            zIndex: 100000,
            maxHeight: 'auto',
            type: 'POST',
            serviceUrl: '/api/global/search',
            groupBy: 'section',
            showNoSuggestionNotice: true,
            triggerSelectOnValidInput: false,
            width: '350',
            onSelect: function (suggestion) {
                let type = suggestion.section.toLowerCase().slice(0, -1);
                let target = '/network/502e43ad71e844b0d502bc9ac60816eb/' + type + '/' + suggestion.id;
                switch (type) {
                    case 'task':
                        Bus.$emit('openSidebarPanel', type, suggestion.id);
                        break;
                    case 'ticket':
                        Bus.$emit('openSidebarPanel', type, suggestion.id);
                        break;
                    case 'todo':
                        Bus.$emit('openSidebarPanel', type, suggestion.id);
                        break;
                    case 'estimate':
                        Bus.$emit('openSidebarPanel', type, suggestion.id);
                        break;
                    default:
                        $(location).attr('href', target);
                }
            },
            transformResult: function (response) {
                response = JSON.parse(response);
                var collectedResponse = new Array();
                response.clients.forEach(function (entry) {
                    entry.section = 'Clients';
                    collectedResponse.push(entry);
                });
                response.projects.forEach(function (entry) {
                    entry.section = 'Projects';
                    collectedResponse.push(entry);
                });
                response.tasks.forEach(function (entry) {
                    entry.section = 'Tasks';
                    collectedResponse.push(entry);
                });
                response.tickets.forEach(function (entry) {
                    entry.section = 'Tickets';
                    collectedResponse.push(entry);
                });
                response.todos.forEach(function (entry) {
                    entry.section = 'Todos';
                    collectedResponse.push(entry);
                });
                response.estimates.forEach(function (entry) {
                    entry.section = 'Estimates';
                    collectedResponse.push(entry);
                });
                return {
                    suggestions: $.map(collectedResponse, function (dataItem) {
                        var name;
                        if (typeof dataItem.company !== 'undefined') {
                            name = dataItem.company;
                        } else if (typeof dataItem.name !== 'undefined') {
                            name = dataItem.name;
                        } else {
                            name = dataItem.first_name + ' ' + dataItem.last_name;
                        }
                        return {
                            value: name,
                            data: {section: dataItem.section},
                            id: dataItem.id,
                            image: dataItem.image,
                            section: dataItem.section
                        };
                    })
                };
            },
            formatResult: function (suggestion) {
                var section = suggestion.section.toLowerCase();
                var formattedResult = '';
                if (section === 'clients' || section === 'projects') {
                    var img = '<div class="media-object suggestion-image border border-purple br64 bw1">';
                    img += '<img src="' + get_image_thumbnail(suggestion.image, '20x20', section) + '" />';
                    img += '</div>';
                    formattedResult += img;
                }

                return formattedResult + '<div class="suggestion-text" data-item-id="' + suggestion.id + '">' + suggestion.value + '</div>';
            }
        });
    });

});

/*
 * Sidebar Panel
 * */

$(document).on('click', '.sidebar-panel', function (event) {
    event.preventDefault();
    Bus.$emit('openSidebarPanel', event.currentTarget.dataset.type.slice(0, -1), event.currentTarget.dataset.item);
});

function sidebarPanel(currentItem, requestUrl) {
    /*$.ajax({
        type: 'GET',
        url: requestUrl
    }).done(function (response) {
        localStorage.setItem('currentTask', currentItem);
        $('body').addClass('overlay');
        $('.cd-panel-content').html(response);
        loadEditable();
        dropzoneUpload();
        $('.cd-panel').addClass('is-visible');
        $(window).resize();


    }).fail(function (msg) {
        alert("Not successfully: " + msg);
    });*/
}

$(document).on('click', '.cd-panel', function (event) {
    if ($(event.target).is('.cd-panel') || $(event.target).is('.cd-panel-close')) {
        $('.cd-panel').removeClass('is-visible');
        setTimeout(function(){
            $('body').removeClass('overlay');
        }, 600);
        event.preventDefault();
    }
});

$(window).resize(function () {
    setItemContainerHeight();
    /*
    setTicketContainerHeight();
    setTodoContainerHeight();
    setEstimateContainerHeight();*/
});


function setItemContainerHeight() {
    /*var taskContainerHeight = $(window).height() - $('header').outerHeight() - $('#topbar').outerHeight() - $('.new-task').outerHeight() - 187;
    $('.item-details-container').height(taskContainerHeight);*/
    var panelContainer = $('.cd-panel-content');
    var itemAdditionalSectionContent = panelContainer.find('.item-additional-section-content');
    var itemAdditionalSectionContentHeight = panelContainer.outerHeight()
        - panelContainer.find('.item-details-header').outerHeight()
        - panelContainer.find('.item-attributes').outerHeight();

    var timeline = panelContainer.find('.item-details-timeline');
    var timelineHeight = panelContainer.outerHeight()
        - panelContainer.find('.item-details-header').outerHeight()
        - panelContainer.find('.item-attributes').outerHeight()
        - panelContainer.find('.comment-field').outerHeight();

    timeline.height(timelineHeight);
    itemAdditionalSectionContent.height(itemAdditionalSectionContentHeight);
}

function loadEditable() {
    $('.editable').editable();
}



var addClientForm = $('#add-client').find('form');

addClientForm.find('input.save').on('click',function() {
    var requestUrl = "/clients";
    var data = addClientForm.serialize();
    actionAjax("POST",requestUrl, data, null);
});

Dropzone.options.addClientImage = {
    url: '/images',
    addRemoveLinks: true,
    maxFiles: 1,
    previewsContainer: ".previews-client",
    clickable: '.previews-client',
    thumbnailWidth: '160',
    thumbnailHeight: '160',
    sending: function(file, xhr, formData) {
        formData.append('_token', $('[name=_token]').val());
    },
    init: function() {
        this.on("addedfile", function(file) {
            addClientForm.find('[name="client-image"]').val(file.name);
            addClientForm.find('#previews-client').removeClass('placeholder');
        });
        this.on("removedfile", function() {
            addClientForm.find('#previews-client').addClass('placeholder');
        });
        this.on("maxfilesexceeded", function(file) {
            this.removeAllFiles();
            this.addFile(file);
        });
    }
};

addClientForm.find('#company').autocomplete({
    minChars:3,
    zIndex: 100000,
    maxHeight: 'auto',
    type:'POST',
    serviceUrl:'/api/whmcs/search',
    onSelect: function (suggestion) {
        $.ajax({
            type: "POST",
            url: '/api/whmcs/getclient',
            data: {client_id: suggestion.data}
        }).done(function( response ) {
            response = JSON.parse(response);
            $.each(response, function(key, value){
                addClientForm.find('[name="'+key+'"]').val(value);
            });
        });
    },
    transformResult: function(response) {
        response = JSON.parse(response);
        return {
            suggestions: $.map(response, function(dataItem) {
                return { value: dataItem.companyname, data: dataItem.id };
            })
        };
    }

});

var addProjectForm = $('#add-project').find('form');
Dropzone.options.addProjectImage = {
    url: '/images',
    addRemoveLinks: true,
    maxFiles: 1,
    previewsContainer: ".previews-project",
    clickable: '.previews-project',
    thumbnailWidth: '160',
    thumbnailHeight: '160',
    sending: function(file, xhr, formData) {
        formData.append('_token', $('[name=_token]').val());
    },
    init: function() {
        this.on("addedfile", function(file) {
            addProjectForm.find('[name="project-image"]').val(file.name);
            addProjectForm.find('#previews-project').removeClass('placeholder');
        });
        this.on("removedfile", function() {
            addProjectForm.find('#previews-project').addClass('placeholder');
        });
        this.on("maxfilesexceeded", function(file) {
            this.removeAllFiles();
            this.addFile(file);
        });
    }
};


addProjectForm.find('#clients').select2({
    ajax: {
        dataType: 'json',
        type: 'POST',
        url: '/api/clients/search',
        delay: 250,
        data: function (params) {
            return {
                query: params.term
            }
        },
        processResults: function (clients) {
            var response = $.map(clients, function (client) {
                var name = client.first_name + ' ' + client.last_name;
                if (client.company.length > 0) {
                    name = client.company;
                }
                return {id: client.id, text: name};
            });
            return {
                results: response
            };
        }
    }
});

addProjectForm.find('#users').select2({
    ajax: {
        dataType: 'json',
        type: 'POST',
        url: '/api/users/search',
    delay: 250,
    data: function(params){
        return {
            query: params.term
        }
    },
    processResults: function (users) {
        var response = $.map(users, function(user) {
            var name =  user.first_name+' '+user.last_name;
            return { id: user.id, text: name };
        });
        return {
            results: response
        };
    }
}
});

addProjectForm.find('#on-jira').on('change', function() {
    if ($('#on-jira').prop('checked')) {
        $('.jira-project-type').removeClass('hidden');
    } else {
        $('.jira-project-type').addClass('hidden');
    }
});

addProjectForm.find('button.save').on('click',function() {
    let requestUrl = "/projects";
    let data = $('#add-project-form').serialize();

    $.ajax({
        type: "POST",
        url: requestUrl,
        data: data,
    }).done(function (response) {
        window.location.href = response;
    }).fail(function (xhr) {
        let formErrors = xhr.responseJSON;
        let message = '';

        $.each(formErrors, function(key,value) {
            message += '- ' + value + '\r\n'
        });

        alert(message)
    });
});



/*
 * Add Task logic
 * Detect Keypress and integrate autocomplete
 */

var addTaskForm = $('#add-task-form');
$(document).on('keypress','#add-task [name="name"]',function(e,a){
    var usersContainer = addTaskForm.find('#users');
    if(e.charCode==64) {
        addTaskForm.find('[name="name"]').autocomplete({
            minChars: 1,
            zIndex: 100000,
            maxHeight: 'auto',
            type: 'POST',
            preserveInput: true,
            paramName: 'query',
            serviceUrl: '/api/users/search',
            onSearchStart: function(query) {
            var queryReg = new RegExp('@(.*)');
            query.query = queryReg.exec(query.query)[1];
            return query;
        },
        onSelect: function (suggestion) {
            var img = '<div class="media-object user-image border border-purple br64 bw1">';
            if(suggestion.image!=null) {
                img += '<img src="' + get_image_thumbnail(suggestion.image, '20x20') + '" class="br64" />';
            }else{
                img += '<img src="/img/placeholders/users/placeholder-20x20.png" />';
            }
            img += '</div>';
            usersContainer.append('<div class="user">'+img+'<div class="user-text" data-user-id="'+suggestion.data+'">'+suggestion.value+
                '<input type="hidden" name="users[]" value="'+suggestion.data+'" /></div></div>');
            $(this).autocomplete().dispose();
            $(this).val($(this).val().replace(/@.*/,'')).focus();
        },
        formatResult: function(suggestion,currentValue){
            currentValue = currentValue.replace('@','');
            var htmlSafeString = suggestion.value
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;');

            var pattern = '(' + currentValue.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')';
            // console.log(suggestion.image);
            var img = '<div class="media-object suggestion-image border border-purple br64 bw1">';
            if(suggestion.image!=null) {
                img += '<img src="' + get_image_thumbnail(suggestion.image, '20x20') + '" class="br64" />';
            }else{
                img += '<img src="/img/placeholders/users/placeholder-20x20.png" />';
            }
            img += '</div>';
            return img + '<div class="suggestion-text">'+htmlSafeString.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')+'</div>';
        },
        transformResult: function(response) {
            response = JSON.parse(response);
            return {
                suggestions: $.map(response, function(dataItem) {
                    var name =  dataItem.first_name+' '+dataItem.last_name;
                    return { value: name, data: dataItem.id, image:dataItem.image };
                })
            };
        }

    });
    }
});

/*
 * Add Task logic
 * Get projects and display in dropdown
 */
$('[name="project_id"]').select2({
        dropdownParent: $('#add-task'),
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/api/projects/search',
        delay: 250,
        data: function(params){
            return {
                query: params.term
            }
        },
        processResults: function (projects) {
            var response = $.map(projects, function(project) {
                return { id: project.id, text: project.name, image: project.image };
            });
            return {
                results: response
            };
        }
    },
    templateResult: function (project) {
    var $project = '';
    if(project.image!='') {
        $project = $(
            '<span><img src="' + get_image_thumbnail(project.image, '20x20') + '" class="border border-pruple" /> ' + project.text + '</span>'
        );
    }else{
        $project = $(
            '<span><img src="/img/placeholders/default/placeholder-20x20.png" class="border border-pruple" /><span class="result-text">' + project.text + '</span></span>'
        );
    }
    return $project;
}
});

$('[name="parent_id"]').select2({
    dropdownParent: $('#add-task'),
    ajax: {
        dataType: 'json',
        type: 'POST',
        url: '/api/tasks/search',
        delay: 250,
        data: function(params){
            return {
                query: params.term,
                projectId: $('[name="project_id"]').val()
            }
        },
        processResults: function (tasks) {
            var response = $.map(tasks, function(task) {
                return { id: task.id, text: task.name };
            });
            return {
                results: response
            };
        }
    },
});

$('[name="budget_position_id"]').select2({
    dropdownParent: $('#add-task'),
    ajax: {
        dataType: 'json',
        type: 'GET',
        url: '/api/project/budget-positions/search',
        delay: 250,
        data: function(params){
            return {
                query: params.term,
                projectId: $('[name="project_id"]').val()
            }
        },
        processResults: function (items) {
            let response = $.map(items, function(item) {
                return { id: item.id, text: item.description };
            });

            return {
                results: response
            };
        },
    },
});

addTaskForm.on('click', '.save',function(e) {
    var requestUrl = "/tasks";
    var data = addTaskForm.serialize();
    actionAjax("POST",requestUrl, data, null);
    addTaskForm.find('[name="name"]').val('').focus();
    addTaskForm.find('#users').empty();
    e.preventDefault();
});


var addTicketForm = $('#add-ticket form');


/*
 * Add Ticket logic
 * Detect Keypress and integrate autocomplete
 */
$(document).on('keypress','[name="name"]',function(e,a){
    var usersContainer = $('#users');
    if(e.charCode==64) {
        addTicketForm.find('[name="name"]').autocomplete({
            minChars: 1,
            zIndex: 100000,
            maxHeight: 'auto',
            type: 'POST',
            preserveInput: true,
            paramName: 'query',
            serviceUrl: '/api/users/search',
            onSearchStart: function(query) {
                var queryReg = new RegExp('@(.*)');
                query.query = queryReg.exec(query.query)[1];
                return query;
            },
            onSelect: function (suggestion) {
                var img = '<div class="media-object user-image border border-purple br64 bw1">';
                if(suggestion.image!=null) {
                    img += '<img src="' + get_image_thumbnail(suggestion.image, '20x20') + '" class="br64" />';
                }else{
                    img += '<img src="/img/placeholders/users/placeholder-20x20.png" />';
                }
                img += '</div>';
                usersContainer.append('<div class="user col-md-4">'+img+'<div class="user-text" data-user-id="'+suggestion.data+'">'+suggestion.value+
                    '<input type="hidden" name="users[]" value="'+suggestion.data+'" /></div></div>');
                $(this).autocomplete().dispose();
                $(this).val($(this).val().replace(/@.*/,'')).focus();
            },
            formatResult: function(suggestion,currentValue){
                currentValue = currentValue.replace('@','');
                var htmlSafeString = suggestion.value
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;');

                var pattern = '(' + currentValue.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')';
                // console.log(suggestion.image);
                var img = '<div class="media-object suggestion-image border border-purple br64 bw1">';
                if(suggestion.image!=null) {
                    img += '<img src="' + get_image_thumbnail(suggestion.image, '20x20') + '" class="br64" />';
                }else{
                    img += '<img src="/img/placeholders/users/placeholder-20x20.png" />';
                }
                img += '</div>';
                return img + '<div class="suggestion-text">'+htmlSafeString.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')+'</div>';
            },
            transformResult: function(response) {
                response = JSON.parse(response);
                return {
                    suggestions: $.map(response, function(dataItem) {
                        var name =  dataItem.first_name+' '+dataItem.last_name;
                        return { value: name, data: dataItem.id, image:dataItem.image };
                    })
                };
            }

        });
    }
});

/*
 * Add Ticket logic
 * Get clients and display in dropdown
 */
addTicketForm.find('[name="client_id"]').select2({
        dropdownParent: $('#add-ticket'),
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/api/clients/search',
        delay: 250,
        data: function(params){
            return {
                query: params.term
            }
        },
        processResults: function (clients) {
            var response = $.map(clients, function(client) {
                var name;
                if(client.company.length){
                    name = client.company;
                }else{
                    name = client.first_name +' '+client.last_name;
                }
                return { id: client.id, text: name, image: client.image };
            });
            return {
                results: response
            };
        }
    },
    templateResult: function (client) {
    var $client = '';
    if(client.image!='') {
        $client = $(
            '<span><img src="' + get_image_thumbnail(client.image, '20x20') + '" class="border border-pruple" /> ' + client.text + '</span>'
        );
    }else{
        $client = $(
            '<span><img src="/img/placeholders/default/placeholder-20x20.png" class="border border-pruple" /><span class="result-text">' + client.text + '</span></span>'
        );
    }
    return $client;
}
});

$('#add-ticket-form').on('click', '.save', function(e) {
    var requestUrl = "/tickets";
    var data = addTicketForm.serialize();
    actionAjax("POST",requestUrl, data, null);
    addTicketForm.find('[name="name"]').val('').focus();
    addTicketForm.find('#users').empty();
    e.preventDefault();
});


var addTodoForm = $('#add-todo').find('form');
/*
 * Add Todo logic
 * Detect Keypress and integrate autocomplete
 */
addTodoForm.on('keypress','[name="name"]',function(e,a){
    var userContainer = $('#user');
    if(e.charCode==64) {
        addTodoForm.find('[name="name"]').autocomplete({
            minChars: 1,
            zIndex: 100000,
            maxHeight: 'auto',
            type: 'POST',
            preserveInput: true,
            paramName: 'query',
            serviceUrl: '/api/users/search',
            onSearchStart: function(query) {
            var queryReg = new RegExp('@(.*)');
            query.query = queryReg.exec(query.query)[1];
            return query;
        },
        onSelect: function (suggestion) {
            var img = '<div class="media-object user-image border border-purple br64 bw1">';
            if(suggestion.image!=null) {
                img += '<img src="' + get_image_thumbnail(suggestion.image, '20x20') + '" class="br64" />';
            }else{
                img += '<img src="/img/placeholders/users/placeholder-20x20.png" />';
            }
            img += '</div>';
            userContainer.html('<div class="user col-md-4">'+img+'<div class="user-text" data-user-id="'+suggestion.data+'">'+suggestion.value+
                '<input type="hidden" name="user_id" value="'+suggestion.data+'" /></div></div>');
            $(this).autocomplete().dispose();
            $(this).val($(this).val().replace(/@.*/,'')).focus();
        },
        formatResult: function(suggestion,currentValue){
            currentValue = currentValue.replace('@','');
            var htmlSafeString = suggestion.value
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;');

            var pattern = '(' + currentValue.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')';
            // console.log(suggestion.image);
            var img = '<div class="media-object suggestion-image border border-purple br64 bw1">';
            if(suggestion.image!=null) {
                img += '<img src="' + get_image_thumbnail(suggestion.image, '20x20') + '" class="br64" />';
            }else{
                img += '<img src="/img/placeholders/users/placeholder-20x20.png" />';
            }
            img += '</div>';
            return img + '<div class="suggestion-text">'+htmlSafeString.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')+'</div>';
        },
        transformResult: function(response) {
            response = JSON.parse(response);
            return {
                suggestions: $.map(response, function(dataItem) {
                    var name =  dataItem.first_name+' '+dataItem.last_name;
                    return { value: name, data: dataItem.id, image:dataItem.image };
                })
            };
        }

    });
    }
});

/*
 * Add Todo logic
 * Get Store Todo
 */
$(document).ready(function () {
    $('#add-todo-form').on('submit', function(e) {
        e.preventDefault();
        var requestUrl = "/todos";
        var data = addTodoForm.serialize();
        actionAjax("POST",requestUrl, data, null);
        addTodoForm.find('[name="name"]').val('').focus();
        addTodoForm.find('#user').empty();
        $(this).closest('.modal').modal('hide');
    });
});

var addEstimateForm = $('#add-estimate').find('form');
/*
addEstimateForm.find('input.save').on('click',function() {
    var requestUrl = "/estimates";
    var data = addEstimateForm.serialize();
    actionAjax("POST",requestUrl, data, null);
});
*/
Dropzone.options.addEstimateImage = {
    url: '/images',
    addRemoveLinks: true,
    maxFiles: 1,
    previewsContainer: ".previews-estimate",
    clickable: '.previews-estimate',
    thumbnailWidth: '160',
    thumbnailHeight: '160',
    sending: function(file, xhr, formData) {
        formData.append('_token', $('[name=_token]').val());
    },
    init: function() {
        this.on("addedfile", function(file) {
            addEstimateForm.find('[name="estimate-image"]').val(file.name);
            addEstimateForm.find('#previews-estimate').removeClass('placeholder');
        });
        this.on("removedfile", function() {
            addEstimateForm.find('#previews-estimate').addClass('placeholder');
        });
        this.on("maxfilesexceeded", function(file) {
            this.removeAllFiles();
            this.addFile(file);
        });
    }
};


addEstimateForm.find('[name="client"]').select2({
    dropdownParent: $('#add-estimate'),
    ajax: {
        dataType: 'json',
        type: 'POST',
        url: '/api/clients/search',
        delay: 250,
        data: function(params){
            return {
                query: params.term
            }
        },
        processResults: function (clients) {
            var response = $.map(clients, function(client) {
                var name;
                if(client.company.length){
                    name = client.company;
                }else{
                    name = client.first_name +' '+client.last_name;
                }
                return { id: client.id, text: name, image: client.image };
            });
            return {
                results: response
            };
        }
    },
    templateResult: function (client) {
        var $client = '';
        if(client.image!='') {
            $client = $(
                '<span><img src="' + get_image_thumbnail(client.image, '20x20') + '" class="border border-pruple" /> ' + client.text + '</span>'
            );
        }else{
            $client = $(
                '<span><img src="/img/placeholders/default/placeholder-20x20.png" class="border border-pruple" /><span class="result-text">' + client.text + '</span></span>'
            );
        }
        return $client;
    }
});

addEstimateForm.find('#users').select2({
    ajax: {
        dataType: 'json',
        type: 'POST',
        url: '/api/users/search',
        delay: 250,
        data: function(params){
            return {
                query: params.term
            }
        },
        processResults: function (users) {
            var response = $.map(users, function(user) {
                var name =  user.first_name+' '+user.last_name;
                return { id: user.id, text: name };
            });
            return {
                results: response
            };
        }
    }
});

addEstimateForm.on('click', '.save', function(e) {
    var requestUrl = "/estimates";
    var data = addEstimateForm.serialize();
    actionAjax("POST",requestUrl, data, null);
    addEstimateForm.trigger("reset");
    addEstimateForm.find('#client').select2('val', '');
    e.preventDefault();
});

var addSubscriptionForm = $('#add-subscription-form');

addSubscriptionForm.find('[name="client_id"]').select2({
    dropdownParent: $('#add-subscription'),
    ajax: {
        dataType: 'json',
        type: 'POST',
        url: '/api/clients/search',
        delay: 250,
        data: function(params){
            return {
                query: params.term
            }
        },
        processResults: function (clients) {
            var response = $.map(clients, function(client) {
                var name;
                if(client.company.length){
                    name = client.company;
                }else{
                    name = client.first_name +' '+client.last_name;
                }
                return { id: client.id, text: name, image: client.image };
            });
            return {
                results: response
            };
        }
    },
    templateResult: function (client) {
        var $client = '';
        if(client.image!='') {
            $client = $(
                '<span><img src="' + get_image_thumbnail(client.image, '20x20') + '" class="border border-pruple" /> ' + client.text + '</span>'
            );
        }else{
            $client = $(
                '<span><img src="/img/placeholders/default/placeholder-20x20.png" class="border border-pruple" /><span class="result-text">' + client.text + '</span></span>'
            );
        }
        return $client;
    }
});

addSubscriptionForm.find('[name="service_id"]').select2({
    dropdownParent: $('#add-subscription'),
    ajax: {
        dataType: 'json',
        type: 'POST',
        url: '/api/services/search',
        delay: 250,
        data: function(params){
            return {
                query: params.term
            }
        },
        processResults: function (clients) {
            var response = $.map(clients, function(client) {
                var name;
                if(client.company.length){
                    name = client.company;
                }else{
                    name = client.first_name +' '+client.last_name;
                }
                return { id: client.id, text: name, image: client.image };
            });
            return {
                results: response
            };
        }
    },
    templateResult: function (subscription) {
        console.log(subscription);
        /*var $client = '';
        if(client.image!='') {
            $client = $(
                '<span><img src="' + get_image_thumbnail(client.image, '20x20') + '" class="border border-pruple" /> ' + client.text + '</span>'
            );
        }else{
            $client = $(
                '<span><img src="/img/placeholders/default/placeholder-20x20.png" class="border border-pruple" /><span class="result-text">' + client.text + '</span></span>'
            );
        }*/
        return $client;
    }
});

addSubscriptionForm.find('#service_id').select2({
    ajax: {
        dataType: 'json',
        type: 'POST',
        url: '/api/services/search',
        delay: 250,
        data: function(params){
            return {
                q: params.term
            }
        },
        processResults: function (services) {
            var response = $.map(services, function(service) {
                return { id: service.id, text: service.name };
            });
            return {
                results: response
            };
        }
    }
});

addSubscriptionForm.on('click', '.save', function(e) {
    var requestUrl = "/subscriptions";
    var data = addSubscriptionForm.serialize();
    actionAjax("POST",requestUrl, data, null);
    addSubscriptionForm.trigger("reset");
    addSubscriptionForm.find('#client_id').select2('val', '');
    addSubscriptionForm.find('#service_id').select2('val', '');
    e.preventDefault();
});

addSubscriptionForm.on("select2:select", '#service_id', function(e) {
    var data = e.params.data;
    $.ajax({
        type: 'GET',
        url: '/api/service/' + e.params.data.id,
    }).done(function (response) {
        addSubscriptionForm.find('#setup_price').val(response.setup_price);
        addSubscriptionForm.find('#price').val(response.price);
    });
});

/*addEstimateForm.find('button.save').on('click',function() {
    var requestUrl = "/estimates";
    var data = $('#add-estimate-form').serialize();
    actionAjax("POST",requestUrl, data, null);
});
*/


/*
$(document).on('click', '.item-attribute-section', function (e) {

    $('.item-attribute-section').each(function(){
        $(this).popover('destroy');
    });
    var itemAttributesPopovers = {
        client: '<select name="client" id="add-client"></select>',
        project: '<select name="project" id="add-project"></select>',
        category: '<select name="category" id="assign-category"></select>',
        createdUser: '<select name="created-user" id="add-created-user"></select>',
        assign: '<select name="user" id="add-user"></select>',
        duedate: '<div class="due-date datepicker" ></div>',
        assignedSprint: '<select class="status" id="assigned-sprint" ></select>',
        status: '<select class="status" id="sprint-status" ></select>'
    };

    var popoverContent = $(this).data('popover-content');
    var sectionPopover = $(this).popover({
        html: true,
        placement: 'bottom',
        delay: 0,
        content: function () {
            return itemAttributesPopovers[popoverContent]
        }
    });
    $(this).popover('toggle');

    $('#add-project').select2({
        placeholder: "Select a project",
        allowClear: true,
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/api/projects/search',
            delay: 250,
            data: function (params) {
                return {
                    query: params.term
                }
            },
            processResults: function (projects) {
                var response = $.map(projects, function (project) {
                    return {id: project.id, text: project.name, image: project.image};
                });
                return {
                    results: response
                };
            }
        },
        templateResult: function (project) {
            var $project = '';
            if (project.image != '' && project.image != null) {
                $project = $(
                    '<img src="' + get_image_thumbnail(project.image, '20x20') + '" class="border border-pruple" /><span class="result-text">' + project.text + '</span>'
                );
            } else {
                $project = $(
                    '<img src="/img/placeholders/default/placeholder-20x20.png" class="border border-pruple" /><span class="result-text">' + project.text + '</span>'
                );
            }
            return $project;
        }
    }).on('select2:select', function () {
        var item = $(this).parents('.item-details');
        var requestUrl = '/'+item.data('item-type')+'/'+item.data('item-id');
        var data = {
            pk: item.data('item-id'),
            name: 'project_id',
            value: $(this).val()
        };
        actionAjax("PUT", requestUrl, data);
        $('.item-attribute-section.project').find('.attribute-value span').text($(this).text());
        $(sectionPopover).popover('hide');
    });
    $('#add-created-user').select2({
        placeholder: "Select a user",
        allowClear: true,
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/users/search',
            delay: 250,
            data: function (params) {
                return {
                    query: params.term
                }
            },
            processResults: function (users) {
                var response = $.map(users, function (user) {
                    var name = user.first_name + ' ' + user.last_name;
                    return {id: user.id, text: name, image: user.image};
                });
                return {
                    results: response
                };
            }
        },
        templateResult: function (user) {
            var $user = '';
            if (user.image != '' && user.image != null) {
                $user = $(
                    '<img src="' + get_image_thumbnail(user.image, '20x20') + '" class="border border-pruple br64" /><span class="result-text">' + user.text + '</span>'
                );
            } else {
                $user = $(
                    '<img src="/img/placeholders/users/placeholder-20x20.png" class="border border-pruple br64" /><span class="result-text">' + user.text + '</span>'
                );
            }
            return $user;
        }
    }).on('select2:select', function (el, ev) {
        var item = $(this).parents('.item-details');
        var requestUrl = '/'+item.data('item-type')+'/'+item.data('item-id');
        var data = {
            pk: item.data('item-id'),
            name: 'created_user_id',
            value: $(this).val()
        };
        actionAjax("PUT", requestUrl, data);
        var image = null;
        var newUser = '<div class="media-object extra-small-image border border-purple br64 bw1">';
        if (image != '' && image != null) {
            newUser += '<img src="' + get_image_thumbnail(user.image, '20x20') + '" class="border border-pruple br64" /><span class="result-text">' + $(this).text() + '</span>';
        } else {
            newUser += '<img src="/img/placeholders/users/placeholder-20x20.png" class="border border-pruple br64" /><span class="result-text">' + $(this).text() + '</span>';
        }
        newUser += '<a href="#" data-user-id="' + $(this).val() + '"  class="remove-created-user" rel="nofollow"><i class="glyphicons glyphicons-remove"></i></a>';
        newUser += '</div>';
        $('.item-attribute-section.created-user').find('.attribute-value span').html(newUser);
        $(sectionPopover).popover('hide');
    });
    $('#add-user').select2({
        placeholder: "Select a user",
        allowClear: true,
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/users/search',
            delay: 250,
            data: function (params) {
                return {
                    query: params.term
                }
            },
            processResults: function (users) {
                var response = $.map(users, function (user) {
                    var name = user.first_name + ' ' + user.last_name;
                    return {id: user.id, text: name, image: user.image};
                });
                return {
                    results: response
                };
            }
        },
        templateResult: function (user) {
            var $user = '';
            if (user.image != '' && user.image != null) {
                $user = $(
                    '<img src="' + get_image_thumbnail(user.image, '20x20') + '" class="border border-pruple br64" /><span class="result-text">' + user.text + '</span>'
                );
            } else {
                $user = $(
                    '<img src="/img/placeholders/users/placeholder-20x20.png" class="border border-pruple br64" /><span class="result-text">' + user.text + '</span>'
                );
            }
            return $user;
        }
    }).on('select2:select', function (el, ev) {
        var item = $(this).parents('.item-details');
        var requestUrl = '/'+item.data('item-type')+'/user';
        var data = {
            pk: item.data('item-id'),
            users: [$(this).val()]
        };
        actionAjax("PUT", requestUrl, data);
        var image = null;
        var newUser = '<div class="media-object extra-small-image border border-purple br64 bw1">';
        if (image != '' && image != null) {
            newUser += '<img src="' + get_image_thumbnail(user.image, '20x20') + '" class="border border-pruple br64" /><span class="result-text">' + $(this).text() + '</span>';
        } else {
            newUser += '<img src="/img/placeholders/users/placeholder-20x20.png" class="border border-pruple br64" /><span class="result-text">' + $(this).text() + '</span>';
        }
        newUser += '<a href="#" data-user-id="' + $(this).val() + '"  class="remove-user" rel="nofollow"><i class="glyphicons glyphicons-remove"></i></a>';
        newUser += '</div>';
        $('.item-attribute-section.assigned').find('.attribute-value').append(newUser);
        $(sectionPopover).popover('hide');
    });

    $('#add-client').select2({
        placeholder: "Select a client",
        allowClear: true,
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/api/clients/search',
            delay: 250,
            data: function(params){
                return {
                    query: params.term
                }
            },
            processResults: function (clients) {
                var response = $.map(clients, function(client) {
                    var name;
                    if(client.company.length){
                        name = client.company;
                    }else{
                        name = client.first_name +' '+client.last_name;
                    }
                    return { id: client.id, text: name, image: client.image };
                });
                return {
                    results: response
                };
            }
        },
        templateResult: function (client) {
            var $client = '';
            if(client.image!=''&&client.image!=null) {
                $client = $(
                    '<img src="' + get_image_thumbnail(client.image, '20x20') + '" class="border border-pruple" /><span class="result-text">' + client.text + '</span>'
                );
            }else{
                $client = $(
                    '<img src="/img/placeholders/default/placeholder-20x20.png" class="border border-pruple" /><span class="result-text">' + client.text + '</span>'
                );
            }
            return $client;
        }
    }).on('select2:select', function() {
        var requestUrl = "/tickets/"+localStorage.getItem('currentTicket');
        var data = {
            pk: localStorage.getItem('currentTicket'),
            name: 'client_id',
            value: $(this).val()
        };
        actionAjax("PUT",requestUrl, data);
        $('.ticket-attribute-section.client').find('.attribute-value span').text($(this).text());
        $(sectionPopover).popover('hide');
    });

    $('.popover .datepicker').datepicker({
        keyboardNavigation: false,
        forceParse: false
    }).on('changeDate', function (e) {
        var monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        var date = new Date(e.date.toISOString());
        var item = $(this).parents('.item-details');
        var requestUrl = '/'+item.data('item-type')+'/'+item.data('item-id');
        var data = {
            pk: item.data('item-id'),
            name: 'due_date',
            value: date.toISOString()
        };
        actionAjax("PUT", requestUrl, data);
        $('.item-attribute-section.duedate').find('.attribute-value span').text(date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear());
        $(sectionPopover).popover('hide');

    });

    $('#assigned-sprint').select2({
        placeholder: "Select a sprint",
        allowClear: true,
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/api/agile/sprints',
            delay: 250,
            data: function(params){
                return {
                    query: params.term
                }
            },
            processResults: function (statuses) {
                var response = $.map(statuses, function(status, i) {
                    return { id: status.id, text: status.name, running: status.is_running };
                });
                return {
                    results: response
                };
            }
        },
        templateResult: function (status) {
            let $status = status.running
                ? $('<span class="result-text"><b>' + status.text + '</b></span>')
                : $('<span class="result-text">' + status.text + '</span>');

            return $status;
        }
    }).on('select2:select', function() {
        var currentItem = $(this).parents('.item-details');
        //var requestUrl = "/" + currentItem.data('item-type') + "/" + currentItem.data('item-id');
        var requestUrl = '/api/' + currentItem.data('item-type') + '/assign-sprint';
        var data = {
            itemId: currentItem.data('item-id'),
            sprintId: $(this).val()
        };
        actionAjax('POST',requestUrl, data);
        currentItem.find('.item-attribute-section.assigned-sprint .attribute-value span').text($(this).text());
        currentItem.find('.item-attribute-section.sprint-status .attribute-value span').text('Ready');
        $(sectionPopover).popover('hide');
    });

    $('#sprint-status').select2({
        placeholder: "Select a status",
        allowClear: true,
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/api/agile/sprint-status',
            delay: 250,
            data: function(params){
                return {
                    query: params.term
                }
            },
            processResults: function (statuses) {
                var response = $.map(statuses, function(status, i) {
                    return { id: i, text: status };
                });
                return {
                    results: response
                };
            }
        },
        templateResult: function (status) {
            var $status = $(
                    '<span class="result-text">' + status.text + '</span>'
                );
            return $status;
        }
    }).on('select2:select', function() {
        var currentItem = $(this).parents('.item-details');
        var requestUrl = "/" + currentItem.data('item-type') + "/" + currentItem.data('item-id');
        var data = {
            pk: currentItem.data('item-id'),
            name: 'sprint_status',
            value: $(this).val()
        };
        actionAjax("PUT",requestUrl, data);
        currentItem.find('.item-attribute-section.sprint-status .attribute-value span').text($(this).text());
        $(sectionPopover).popover('hide');
    });

    $('#assign-category').select2({
        placeholder: "Select a category",
        allowClear: true,
        ajax: {
            dataType: 'json',
            type: 'POST',
            url: '/api/agile/issue/categories',
            delay: 250,
            data: function(params){
                return {
                    query: params.term
                }
            },
            processResults: function (categories) {
                var response = $.map(categories, function(category, i) {
                    return { id: category.id, text: category.name, color: category.color };
                });
                return {
                    results: response
                };
            }
        },
        templateResult: function (category) {
            return $(
                '<div class="color-square" style="background-color: ' + category.color + '"></div><span class="result-text">' + category.text + '</span>'
            );
        }
    }).on('select2:select', function() {
        var currentItem = $(this).parents('.item-details');
        var requestUrl = "/" + currentItem.data('item-type') + "/" + currentItem.data('item-id');
        var data = {
            pk: currentItem.data('item-id'),
            name: 'category_id',
            value: $(this).val()
        };
        actionAjax("PUT",requestUrl, data);
        currentItem.find('.item-attribute-section.issue-category .attribute-value span').text($(this).text());
        $(sectionPopover).popover('hide');
    });
     $(document).on('click','.popover .datepicker',function() {
     console.log('yes');
     $(this).datepicker({
     keyboardNavigation: false,
     forceParse: false
     });
     });
});*/

// $(document).on('click', '.item-attribute-section .remove-created-user', function () {
//     var item = $(this).parents('.item-details');
//     var requestUrl = '/'+item.data('item-type')+'/created-user';
//     var data = {
//         pk: item.data('item-id'),
//         user: $(this).data('user-id')
//     };
//     actionAjax("POST", requestUrl, data);
//     $(this).parent().remove();
// });
//
// $(document).on('click', '.item-attribute-section .remove-user', function () {
//     var item = $(this).parents('.item-details');
//     var requestUrl = '/'+item.data('item-type')+'/user';
//     var data = {
//         pk: item.data('item-id'),
//         user: $(this).data('user-id')
//     };
//     actionAjax("POST", requestUrl, data);
//     $(this).parent().remove();
// });

// $(document).on('click', '.comment-field .save', function () {
//     var type = $(this).parents('.comment-field').data('comment-type');
//     var requestUrl = '/comments/'+type;
//     var commentInput = $(this).parents('.comment-field').find('.comment');
//     var data = {
//         comment: commentInput.val(),
//         item_id: $(this).parents('.item-details').data('source-id')
//     };
//     actionAjax("POST", requestUrl, data, '#timeline');
//     commentInput.val('');
//     return false;
// });


$(document).on('focus', '[name="comment"]', function () {
    var offset = this.offsetHeight - this.clientHeight;
    var resizeTextarea = function (el) {
        $(el).css('height', 'auto').css('height', el.scrollHeight + offset);
    };
    $(this).on('keyup input', function (e) {
        // if (e.keyCode == 13) {
        //     if (e.shiftKey === true) {
        //         $(this).parents('.comment-field').find('.save').click();
        //         $(this).val('');
        //     }
        //     return false;
        // }
        resizeTextarea(this);
    });

    $('.comment-options').slideDown();
});

/*$(document).on('focusout','[name="comment"]',function(){
 $('.comment-options').slideUp();
 });*/
$(document).mouseup(function (e) {
    var container = $(".comment-field");

    if (!container.is(e.target) // if the target of the click isn't the container...
        && container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        $('.comment-options').slideUp();
    }
});


$(document).on('change', '.item-details .checkmark :checkbox', function () {
    var itemContainer = $(this).parents('.item-details');
    var itemRow = itemContainer.find('.item-details-title');
    var itemType = itemContainer.data('item-type');
    var itemCheckmark = $('#'+itemType+'-list [data-item-id="' + itemContainer.data('item-id') + '"]').find('.checkmark :checkbox');
    if ($(this).prop('checked')) {
        itemRow.addClass('done');
        itemCheckmark.prop('checked', true).trigger('change');
    } else {
        itemRow.removeClass('done');
        itemCheckmark.prop('checked', false).trigger('change');
    }
    var requestUrl = "/" + itemType + "/" + itemContainer.data('item-id');
    var data = {
        pk: itemContainer.data('item-id'),
        name: 'status',
        value: $(this).val()
    };
    actionAjax("PUT", requestUrl, data, null);
});


function dropzoneUpload(type) {
    var previewNode = $('#item-template');
    previewNode.attr('id', '');
    var previewTemplate = previewNode.parent().html();
    previewNode.remove();
    let dropzoneContainer = $('#item-upload-file');
    let dropzoneControl = dropzoneContainer[0].dropzone;
    if (dropzoneControl) {
        dropzoneControl.destroy();
    }
    dropzoneContainer.dropzone({
        url: '/resources/' + type,
        paramName: 'file',
        autoQueue: true,
        uploadMultiple: true,
        previewTemplate: previewTemplate,
        thumbnailWidth: 80,
        thumbnailHeight: 80,
        previewsContainer: '#item-previews',
        maxFilesize: 2,
        clickable: '.fileinput-button',
        init: function () {
            //var submitButton = document.querySelector('#start-upload');
            var myDropzone = this;

            // submitButton.addEventListener('click', function (e) {
            //     e.preventDefault();
            //     e.stopPropagation();
            //     myDropzone.enqueueFiles(myDropzone.getFilesWithStatus(Dropzone.ADDED));
            // });

            this.on('success', function (file, response) {
                myDropzone.removeAllFiles(true);
                Bus.$emit('submittedFile', response);
                //$('#timeline').html(response);
                //loadEditable();
                $(window).resize();
            });
        }
    });
}

$(document).on('click', 'li.disabled a', function (e) {
    e.preventDefault();
    e.stopPropagation();
});

function actionAjax(type, requestUrl, data, container, callback) {
    $.ajax({
        type: type,
        url: requestUrl,
        data: data,
        beforeSend: function () {
            //loadSpinner();
        }
    }).done(function (response) {
        $(container).html(response);
        loadEditable();
        $(window).resize();
        //  $('#spinner').empty();
    }).fail(function (msg) {
        alert("Not successfully: " + msg);
    });
}

/**
 * Get image thumbnails helper
 */
function get_image_thumbnail(image, size, placeholder) {
    if (typeof image != 'undefined' && image && typeof size != 'undefined') {
        var filename = image.substring(image.lastIndexOf('/') + 1);
        return image.replace(filename, size + '/' + filename);
    } else {
        if (typeof placeholder == 'undefined') {
            placeholder = 'default';
        }
        if (typeof size != 'undefined') {
            return '/img/placeholders/' + placeholder + '/placeholder-' + size + '.png';
        } else {
            return '/img/placeholders/' + placeholder + '/placeholder.png';
        }
    }
}

/*window.app = {};

 app.BrainSocket = new BrainSocket(
 new WebSocket('ws://keevo.dev:8080'),
 new BrainSocketPubSub()
 );*/

/*
 * Load Project List when Project Tab is active
 */
$(document).on('show.bs.dropdown', '#tasks-tool', function () {
    var activeTab = localStorage.getItem('activeTab') || "#projects-tab";

    if (activeTab !== null) {
        $('a[href="' + activeTab + '"]').tab('show');
    }
});

/*
 * Set active tab since last session
 */
$(document).on('show.bs.tab', '#tasks-tool a[data-toggle="tab"]', function (e) {
    var target = $(e.target).attr('href');
    localStorage.setItem('activeTab', target);
});

/*
 * Load Most Viewed Projects when tba changes
 */
$(document).on('shown.bs.tab', '#tasks-tool a[data-toggle="tab"]', function (e) {
    loadMostViewedProjects();
    loadMostViewedTasks();
});

function loadMostViewedTasks() {
    if ($('#tasks-tab').hasClass('active')) {
        getFields('tasks', ['id', 'name', 'status', 'project_id']);
    }
}

$(document).on('change', '#tasks-tool .item-checkbox', function () {
    var itemRow = $(this).parents('.task');
    var itemId = itemRow.data('item-id');
    var requestUrl = "/tasks/" + itemId;

    if ($(this).prop('checked')) {
        itemRow.addClass('done');
        $(this).val('1');
    } else {
        itemRow.removeClass('done');
        $(this).val('0');
    }
    var data = {
        pk: itemRow.data('item-id'),
        name: 'status',
        value: $(this).val()
    };
    actionAjax("PUT", requestUrl, data, null);
});

$(document).on('change', '[name="time_entry_billable"], [name="time_entry_delegable"]', function () {
    if ($(this).prop('checked')) {
        $(this).val('1');
    } else {
        $(this).val('0');
    }
});

/*
 * Load Most Viewed Projects function
 */
function loadMostViewedProjects() {
    if ($('#projects-tab').hasClass('active')) {
        getFields('projects', ['id', 'name', 'description', 'image']);
        $('.dropdownSearch').val('');
        slider = slidePanel();
    }
}

/*
 * Initialize Slide Panel Plugin
 */
var slider;

function slidePanel() {
    var slider = $('.slide-panels').slidePanel({
        speed: 400,
        onNextPanel: function (nextSlideElement, e) {
            if (nextSlideElement.hasClass('tasks-list')) {
                if (typeof e === 'object') {
                    var itemId = $(e.currentTarget).data('item-id');
                    getProjectTasks(nextSlideElement, itemId);
                } else {
                    getProjectTasks(nextSlideElement, e);
                }
            }
        },
        onPreviousPanel: function (prevSlideElement, e) {
            //console.log(e);
        }
    });

    return slider;
}

/*
 * Load Most Viewed Projects when tool's open
 */
$('#tasks-tool').on('shown.bs.dropdown', function () {
    loadMostViewedProjects();
    loadMostViewedTasks();
    if (localStorage.getItem('currentTrackedItem') !== 'custom' || localStorage.getItem("lastTrackedItem") !== null) {
        var lastTrackedItem = JSON.parse(localStorage.getItem('lastTrackedItem'));
        $('.current_tracked_item').attr('data-item-id', lastTrackedItem.item_id).attr('data-category-id', lastTrackedItem.time_category_id).show();
        $('.current_tracked_item_name').text(lastTrackedItem.name);
        $('.current_tracked_item_project').text(lastTrackedItem.project_name);
    }
});

/*
 * On Project click, slide to related Tasks
 */
$('#projects-tab').on('click', '.project', function (e) {
    slider.next(e);

    $('.slide-panel-back').removeClass('hidden');
    $('.dropdown-input.search').addClass('hidden');
    $('.dropdown-input.create-task').removeClass('hidden');
});

/*
 * Timer Plugin Initialisation
 */
$(window).on('unload', function () {
    var timerText = $('.timer').text();

    if (timerText !== '' && localStorage.getItem('currentTime') === null && timerText !== 'NaN:NaN:NaN') {
        localStorage.setItem('currentTime', $('.timer').text());
        if (localStorage.getItem('isTimerActive') === 'true') {
            localStorage.setItem('contentUnloaded', new Date());
        }
    }
});


/*
 * Convert HH:MM:SS format to seconds
 */
function HHMMSSToSec(str) {
    var p = str.split(':'),
        s = 0, m = 1;

    while (p.length > 0) {
        s += m * parseInt(p.pop(), 10);
        m *= 60;
    }

    return s;
}

/*
 * Convert seconds to HH:MM:SS format
 */
function secToHHMMSS(sec) {
    var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    var time = hours + ':' + minutes + ':' + seconds;
    return time;
}

function editTimeLegacy(timeId) {
    Bus.$emit('editTimeEntryLegacy', timeId);
}

//
// /*
//  * Start tracking task function
//  */
// function startTrackingTask(time) {
//     $.ajax({
//
//         url: '/api/times/play',
//         method: 'post',
//         data: {
//             user_id: $('meta[name="current-user"]').attr('content'),
//             time_entry: localStorage.getItem('timeEntry'),
//             start_time: moment(time).startOf('minute').unix(),
//             category_id: localStorage.getItem('categoryId'),
//             item_id: localStorage.getItem('currentTrackedItem')
//         }
//
//     }).done(function (data) {
//         localStorage.setItem('timeEntry', data.id);
//         localStorage.setItem('timeEntryData', JSON.stringify(data));
//         localStorage.setItem('lastTrackedItem', JSON.stringify(data));
//
//         if (localStorage.getItem('currentTrackedItem') !== 'custom') {
//             $('.current_tracked_item').attr('data-item-id', data.item_id).attr('data-category-id', data.time_category_id);
//             $('.current_tracked_item_name').text(data.name);
//             $('.current_tracked_item_project').text(data.project_name);
//             checkIfTrackedItem();
//         } else {
//             $('.current_tracked_item').attr('data-item-id', null).hide();
//         }
//     });
//     //}
// }
//
// /*
//  * Stop tracking task function
//  */
// function stopTrackingTask(time) {
//
//     var start_time = moment($('[name="time_entry_start"]').val(), 'HH:mm').startOf('minute').unix(),
//         end_time = moment($('[name="time_entry_end"]').val(), 'HH:mm').startOf('minute').unix();
//
//     if (localStorage.getItem('expiredTime') === 'true') {
//         var daysDifference = localStorage.getItem('daysDifference');
//         start_time = moment.unix(start_time).subtract(daysDifference, 'days').unix();
//         end_time = moment.unix(end_time).subtract(daysDifference, 'days').unix();
//
//         localStorage.removeItem('expiredTime');
//         localStorage.removeItem('daysDifference');
//     }
//     var data = {
//         time_entry: localStorage.getItem('timeEntry'),
//         name: $('[name="time_entry_name"]').val(),
//         category_id: $('[name="time_entry_category"]').val(),
//         description: $('[name="time_entry_description"]').val(),
//         start_time: start_time,
//         end_time: end_time,
//         billable: $('[name="time_entry_billable"]').val(),
//         delegable: $('[name="time_entry_delegable"]').val()
//     };
//     console.log(data);
//     $.ajax({
//         url: '/api/times/stop',
//         method: 'post',
//         data: data
//     }).done(function (data) {
//         localStorage.removeItem('timeEntryData');
//     });
// }
//
// /*
//  * Initialize time element
//  */
// var interval;
// var timer = $(".timer").elapsed({
//     onTimerInit: function () {
//         var currentTimeString = localStorage.getItem('currentTime'),
//             currentTime = 0,
//             currentTimeEntryData = JSON.parse(localStorage.getItem('timeEntryData'));
//
//         if (currentTimeString !== null) {
//             currentTime = HHMMSSToSec(currentTimeString);
//             localStorage.removeItem('currentTime');
//         }
//
//         /*
//          * If timer is active
//          */
//         if (currentTimeString !== null && localStorage.getItem('isTimerActive') === 'true') {
//             $('.timer__button .clock-icon').attr('src', '/img/clock.gif');
//
//             $(window).load(function () {
//                 var contentUnloaded = localStorage.getItem('contentUnloaded'),
//                     contentLoaded = new Date();
//
//                 if (contentUnloaded !== null && contentLoaded !== null) {
//                     var ms = moment(contentLoaded, 'HH:mm:ss').diff(moment(contentUnloaded, 'HH:mm:ss'));
//                     var d = moment.duration(ms).format('s');
//
//                     currentTime = HHMMSSToSec(currentTimeString) + HHMMSSToSec(d) + 1;
//                 }
//
//                 $('.timer').text(secToHHMMSS(currentTime));
//
//                 localStorage.removeItem('contentUnloaded');
//             });
//
//             $('[data-item-id="' + localStorage.getItem('currentTrackedItem') + '"] .timer__start').removeClass('timer__start glyphicons-play').addClass('timer__stop glyphicons-stop');
//             $('.timer-controller.timer__start').removeClass('timer__start glyphicons glyphicons-play').addClass('timer__stop glyphicons glyphicons-stop');
//         }
//
//         if (localStorage.getItem('timeEntry') !== null) {
//             $('.timer-controller.timer__start').removeClass('timer__start glyphicons glyphicons-play').addClass('timer__stop glyphicons glyphicons-stop');
//         }
//
//         if (currentTimeEntryData !== null && localStorage.getItem('currentTrackedItem') !== 'custom') {
//             $('.current_tracked_item_controller').removeClass('glyphicons glyphicons-stop').addClass('glyphicons glyphicons-play');
//             $('.current_tracked_item').attr('data-item-id', currentTimeEntryData.item_id).attr('data-category-id', currentTimeEntryData.time_category_id).slideDown();
//             $('.current_tracked_item_name').text(currentTimeEntryData.name);
//             $('.current_tracked_item_project').text(currentTimeEntryData.project_name);
//         }
//
//         if (currentTimeEntryData === null) {
//             $.ajax({
//
//                 url: '/api/times/activeTimeEntry',
//                 method: 'post'
//
//             }).done(function (data) {
//                 if (data) {
//                     localStorage.setItem('timeEntryData', JSON.stringify(data));
//                     localStorage.setItem('lastTrackedItem', JSON.stringify(data));
//                     localStorage.setItem('isTimerActive', 'true');
//                     localStorage.setItem('timeEntry', data.id);
//                     localStorage.setItem('categoryId', data.time_category_id);
//
//                     if (data.item_id) {
//                         localStorage.setItem('currentTrackedItem', data.item_id)
//                     } else {
//                         localStorage.setItem('currentTrackedItem', 'custom')
//                     }
//
//                     var difference = moment().startOf('minute').diff(moment(data.start_time), 'seconds');
//                     $('.timer').text(secToHHMMSS(difference + 1));
//
//                     location.reload();
//                 }
//             });
//         } else {
//             $.ajax({
//
//                 url: '/api/times/isStillTracking',
//                 method: 'post',
//                 data: {
//                     time_id: currentTimeEntryData.id
//                 }
//
//             }).done(function (data) {
//                 if (data == 'false') {
//                     localStorage.removeItem('timeEntryData');
//                     localStorage.removeItem('lastTrackedItem');
//                     localStorage.removeItem('isTimerActive');
//                     localStorage.removeItem('timeEntry');
//                     localStorage.removeItem('categoryId');
//                     localStorage.removeItem('currentTrackedItem')
//
//                     timer.stop();
//
//                     $('.timer__button .clock-icon').attr('src', '/img/paused_clock.png');
//                     $('.timer-controller.timer__stop').removeClass('timer__stop glyphicons glyphicons-stop').addClass('timer__start glyphicons glyphicons-play');
//                 }
//             });
//         }
//
//         /*
//          * Stop button action
//          */
//         $(document).on('click', '.timer__stop', function (e) {
//             e.stopPropagation();
//             $('#timeTrackingModalUpdate').modal({
//                 show: true,
//                 backdrop: 'static'
//             });
//
//             timer.pause();
//
//             var timeEntryData = JSON.parse(localStorage.getItem('timeEntryData')),
//                 timeDifference = moment().diff(timeEntryData.start_time, 'hours'),
//                 daysDifference = moment().diff(moment(timeEntryData.start_time).startOf('day'), 'days');
//
//             if (timeDifference >= 8) {
//
//                 var fireModal = true;
//
//                 localStorage.setItem('expiredTime', 'true');
//                 localStorage.setItem('daysDifference', daysDifference);
//
//                 $('#timeTrackingModalUpdate').modal({
//                     show: true,
//                     backdrop: 'static'
//                 });
//
//                 $('#timeTrackingModalUpdate').on('shown.bs.modal', function (e) {
//                     if (fireModal) {
//                         $(this).find('.modal-body').prepend('<div class="alert alert-danger" role="alert">It seems that ' + moment(timeEntryData.start_time).fromNow() + ' (' + moment(timeEntryData.start_time).format('dddd, DD.MM.YYYY') + ')' + ' you didn\'t stopped the timer.<br />What you\'ve done and when have you finished on that day?</div>');
//                         $(this).find('.time_entry_end').addClass('has-error has-feedback');
//                         $('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>').insertAfter($(this).find('#time_entry_end'));
//                         $(this).find('#time_entry_end').val('18:00');
//                         $(this).find('#time_entry_end').timepicker('setTime', '18:00');
//                         $(this).find('#time_entry_end').click();
//                     }
//                 });
//
//                 $('#timeTrackingModalUpdate').on('hidden.bs.modal', function (e) {
//                     if (fireModal) {
//                         $(this).find('.time_entry_end').removeClass('has-error has-feedback');
//                         $(this).find('.time_entry_end').find('.form-control-feedback').remove();
//                         $(this).find('.alert-danger').remove();
//                         fireModal = false;
//                     }
//                 });
//
//             }
//
//             $('.timer__stop').removeClass('timer__stop glyphicons glyphicons-stop').addClass('timer__start glyphicons glyphicons-play');
//             $('.timer__button .clock-icon').attr('src', '/img/paused_clock.png');
//         });
//
//         $('#timeTrackingModalUpdate').on('show.bs.modal', function () {
//             var currentTrackedItem = localStorage.getItem('currentTrackedItem');
//             if (currentTrackedItem === 'custom') {
//                 $(this).find('.time_entry_name').show().find('[name="time_entry_name"]').prop('disabled', false);
//                 $(this).find('.time_entry_category').show();
//                 $(this).find('.time_entry_billable').show();
//                 $(this).find('.time_entry_delegable').show();
//                 localStorage.removeItem('lastTrackedItem');
//             } else {
//                 $(this).find('.time_entry_delegable').show();
//                 $(this).find('.time_entry_name').show().find('[name="time_entry_name"]').prop('disabled', true);
//             }
//
//             var timeEntryData = JSON.parse(localStorage.getItem('timeEntryData'));
//             $(this).find('[name="time_entry_name"]').val(timeEntryData.name);
//             $(this).find('[name="time_entry_category"]').val(timeEntryData.time_category_id);
//             $(this).find('[name="time_entry_start"]').val(moment(timeEntryData.start_time).format('HH:mm'));
//             $(this).find('[name="time_entry_end"]').val(moment(new Date()).format('HH:mm'));
//         });
//
//         $('#timeTrackingModalUpdate').on('shown.bs.modal', function () {
//             $(this).find('.timepicker').timepicker({
//                 showMeridian: false,
//                 minuteStep: 1,
//                 defaultTime: false
//             });
//
//             var currentTrackedItem = localStorage.getItem('currentTrackedItem');
//
//             if (currentTrackedItem === 'custom') {
//                 $(this).find('[name="time_entry_name"]').focus();
//             } else {
//                 $(this).find('[name="time_entry_description"]').focus();
//             }
//         });
//
//         $('#timeTrackingModalUpdate').on('hidden.bs.modal', function () {
//             $(this).find('input').val('');
//             $(this).find('textarea').val('');
//             $(this).find('select').val('1');
//             $(this).find('input[type="checkbox"]').val('0').prop('checked', false);
//
//             $(this).find('.time_entry_name').hide();
//             $(this).find('.time_entry_category').hide();
//             $(this).find('.time_entry_billable').hide();
//             $(this).find('.time_entry_delegable').hide();
//         });
//
//         $(document).on('submit', '.update_time_entry', function (e) {
//             e.preventDefault();
//             $('#timeTrackingModalUpdate').modal('hide');
//             timer.stop();
//         });
//
//         /*
//          * Play/Pause button actions
//          */
//         $(document).on('click', '.timer__start', function (e) {
//             e.stopPropagation();
//             e.preventDefault();
//             if ($(e.target).hasClass('timer-controller')) {
//                 timer.play();
//                 localStorage.setItem('currentTrackedItem', 'custom');
//                 $('.timer-controller.timer__start').removeClass('timer__start glyphicons glyphicons-play').addClass('timer__stop glyphicons glyphicons-stop');
//                 $('.current_tracked_item').slideUp();
//             } else {
//                 var category_id;
//                 if ($(e.target).hasClass('current_tracked_item_controller')) {
//                     category_id = $(e.target).parents('.current_tracked_item').data('category-id');
//                 } else {
//                     category_id = $(e.target).data('category-id');
//                 }
//                 console.log(category_id);
//                 timerControllerStart($(this), category_id);
//             }
//         });
//
//         $('.current_tracked_item').on('click', '.timer__stop', function (e) {
//             e.stopPropagation();
//             e.preventDefault();
//             $('.timer-controller.timer__stop').click();
//         });
//
//         $('.tasks-list, .recent-tasks-list').on('click', '.timer__start', function () {
//             timerControllerStart($(this), 1);
//         });
//
//         $('.tasks-list, .recent-tasks-list').on('click', '.timer__stop', function (e) {
//             e.stopPropagation();
//             e.preventDefault();
//             $('.timer-controller.timer__stop').click();
//         });
//
//         function timerControllerStart(button, category_id) {
//             var element = button.closest('[data-item-id]');
//
//             if ((typeof category_id === 'undefined' || category_id === null) && button.closest('[data-category-id]').length > 0) {
//                 category_id = button.closest('[data-category-id]').data('category-id');
//                 localStorage.setItem('categoryId', category_id);
//             } else if (typeof category_id !== 'undefined' && category_id !== null) {
//                 localStorage.setItem('categoryId', category_id);
//             } else {
//                 localStorage.removeItem('categoryId');
//             }
//
//             if (localStorage.getItem('currentTrackedItem') !== null && element.attr('data-item-id') === localStorage.getItem('currentTrackedItem')) {
//                 var timeEntryData = JSON.parse(localStorage.getItem('timeEntryData')),
//                     timeDifference = moment().diff(timeEntryData.start_time, 'hours'),
//                     daysDifference = moment().diff(timeEntryData.start_time, 'days');
//
//                 if (timeDifference >= 8) {
//
//                     var fireModal = true;
//
//                     localStorage.setItem('expiredTime', 'true');
//                     localStorage.setItem('daysDifference', daysDifference);
//
//                     $('#timeTrackingModalUpdate').modal({
//                         show: true,
//                         backdrop: 'static'
//                     });
//
//                     $('#timeTrackingModalUpdate').on('shown.bs.modal', function (e) {
//                         if (fireModal) {
//                             $(this).find('.modal-body').prepend('<div class="alert alert-danger" role="alert">It seems that ' + moment(timeEntryData.start_time).fromNow() + ' (' + moment(timeEntryData.start_time).format('dddd, DD.MM.YYYY') + ')' + ' you didn\'t stopped the timer.<br />When have you finished on that day?</div>');
//                             $(this).find('.time_entry_end').addClass('has-error has-feedback');
//                             $('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>').insertAfter($(this).find('#time_entry_end'));
//                             $(this).find('#time_entry_end').val('18:00');
//                             $(this).find('#time_entry_end').timepicker('setTime', '18:00');
//                             $(this).find('#time_entry_end').click();
//                         }
//                     });
//
//                     $('#timeTrackingModalUpdate').on('hidden.bs.modal', function (e) {
//                         if (fireModal) {
//                             $(this).find('.time_entry_end').removeClass('has-error has-feedback');
//                             $(this).find('.time_entry_end').find('.form-control-feedback').remove();
//                             $(this).find('.alert-danger').remove();
//                             localStorage.setItem('currentTrackedItem', timeEntryData.item_id);
//                             timer.play();
//                             fireModal = false;
//                         }
//                     });
//
//                     $('.timer__stop').removeClass('timer__stop glyphicons-stop').addClass('timer__start glyphicons-play');
//
//                 } else {
//                     timer.play();
//                 }
//             } else if (localStorage.getItem('currentTrackedItem') === null) {
//                 localStorage.setItem('currentTrackedItem', element.data('item-id'));
//                 timer.play();
//             } else {
//                 var fireModal = true;
//
//                 $('#timeTrackingModalUpdate').modal({
//                     show: true,
//                     backdrop: 'static'
//                 });
//                 $('#timeTrackingModalUpdate').on('hidden.bs.modal', function (e) {
//                     if (fireModal) {
//                         localStorage.setItem('currentTrackedItem', element.data('item-id'));
//                         timer.play();
//                         fireModal = false;
//                     }
//                 });
//
//                 $('.timer__stop').removeClass('timer__stop glyphicons-stop').addClass('timer__start glyphicons-play');
//             }
//
//             $('[data-item-id="' + element.data('item-id') + '"] .timer__start').removeClass('timer__start glyphicons-play').addClass('timer__stop glyphicons-stop');
//
//             $('.timer-controller.timer__start').removeClass('timer__start glyphicons glyphicons-play').addClass('timer__stop glyphicons glyphicons-stop');
//         }
//
//         $('.timer').text(secToHHMMSS(currentTime));
//     },
//     onTimerPlayed: function (time) {
//
//         $('.timer__button .clock-icon').attr('src', '/img/clock.gif');
//
//         var timeEntry = localStorage.getItem('timeEntry'),
//             currentTrackedItem = localStorage.getItem('currentTrackedItem');
//
//         startTrackingTask(time);
//
//         function timer() {
//             var seconds = 1,
//                 currentTimeString;
//
//             if (localStorage.getItem('currentTime') !== null) {
//                 currentTimeString = localStorage.getItem('currentTime');
//                 localStorage.removeItem('currentTime');
//             } else {
//                 currentTimeString = $('.timer').text();
//             }
//
//             var currentTime = HHMMSSToSec(currentTimeString),
//                 newTime;
//
//             if (!isNaN(currentTime)) {
//                 newTime = currentTime + seconds;
//             } else {
//                 newTime = seconds;
//             }
//
//             $('.timer').text(secToHHMMSS(newTime));
//         }
//
//         localStorage.setItem('interval', window.setInterval(function () {
//             timer();
//         }, 1000));
//     },
//     onTimerPaused: function (time) {
//         $('.timer__button .clock-icon').attr('src', '/img/paused_clock.png');
//         window.clearInterval(localStorage.getItem('interval'));
//     },
//     onTimerStopped: function (time) {
//         $('.timer').text(secToHHMMSS(0));
//         localStorage.removeItem('currentTime');
//
//         stopTrackingTask(time);
//
//         localStorage.removeItem('currentTrackedItem');
//
//         localStorage.removeItem('timeEntry');
//
//         window.clearInterval(localStorage.getItem('interval'));
//         localStorage.removeItem('interval');
//     }
// }).data('plugin_elapsed');

/*
 * Task creation logic
 */
$('.dropdown-input.create-task').on('keyup', function (e) {
    if (e.keyCode == 13) {
        var element = $(this),
            type = $(this).closest('.tab-pane').data('type'),
            task = $(this).val(),
            user = [
                $('meta[name="current-user"]').attr('content')
            ],
            requestUrl = '/tasks';

        if (type == 'project') {
            var project = localStorage.getItem('currentProject');

            $.ajax({
                type: 'post',
                url: requestUrl,
                data: {
                    name: task,
                    users: user,
                    project_id: project,
                    output: 'json'
                }
            }).done(function (response) {
                element.val('');
                $('.empty-view').remove();
                taskLayout($('.slide-panel.tasks-list'), response);
            }).fail(function (msg) {
                $('.empty-view').remove();
                alert("Not successfully: " + msg);
            });
        } else {

            $.ajax({
                type: 'post',
                url: requestUrl,
                data: {
                    name: task,
                    users: user,
                    output: 'json'
                }
            }).done(function (response) {
                element.val('');
                $('.empty-view').remove();
                taskLayout($('.recent-tasks-list'), response);
            }).fail(function (msg) {
                $('.empty-view').remove();
                alert("Not successfully: " + msg);
            });
        }
    }
});

function taskLayout(element, response) {
    element.prepend('<div class="task table-layout" data-item-id="' + response.id + '" data-category-id="1">' +
        '<div class="ticket-handle va-m">' +
        '<div class="cBox ml10">' +
        '<input type="checkbox" class="task-checkbox" name="check" value="' + response.status + '"/>' +
        '</div>' +
        '</div>' +
        '<div class="ticket-body p10 va-m task__name">' + response.name + '</div>' +
        '<span class="label label-sm bg-grey2 ml10 task__project_name">' + response.project_name + '</span>' +
        '<div class="timer-actions"><span class="timer__start glyphicons glyphicons-play"></span></div>' +
        '</div>');
}

/*
 * Back to Projects view
 */
$('.dropdown-submenu').on('click', '.slide-panel-back', function (e) {
    slider.prev(e);

    $(this).addClass('hidden');
    $('.dropdown-input.search').removeClass('hidden');
    $('.dropdown-input.create-task').addClass('hidden');
});

/*
 * Prevent closing the tool window
 */
$(document).on('click', '.autocomplete-suggestion', function (e) {
    e.stopPropagation();
});

/*
 * Get Tasks related to selected Project function
 */
function getProjectTasks(nextSlideElement, itemId) {
    nextSlideElement.empty();

    var request = $.ajax({
        method: 'get',
        url: '/api/projects/' + itemId + '/tasks'
    });

    request.done(function (msg) {
        localStorage.setItem('currentProject', itemId);

        if (msg.length > 0) {
            $.each(msg, function (index, value) {
                nextSlideElement.append('<div class="task table-layout" data-item-id="' + value.id + '" data-category-id="1">' +
                    '<div class="ticket-handle va-m">' +
                    '<div class="cBox ml10">' +
                    '<input type="checkbox" class="task-checkbox" name="check" value="' + value.status + '"/>' +
                    '</div>' +
                    '</div>' +
                    '<div class="ticket-body p10 va-m task__name">' + value.name + '</div>' +
                    '<div class="timer-actions"><span class="timer__start glyphicons glyphicons-play"></span></div>' +
                    '</div>');
            });

            checkIfTrackedItem();

        } else {
            nextSlideElement.append('<p class="empty-view">No tasks found</p>');
        }
    });
}

function checkIfTrackedItem() {
    var currentTrackedItem = localStorage.getItem('currentTrackedItem'),
        isTimerActive = localStorage.getItem('isTimerActive');

    if (currentTrackedItem !== null) {
        if (isTimerActive === 'true') {
            $('#tasks-tool [data-item-id="' + currentTrackedItem + '"] .timer__start').removeClass('timer__start glyphicons-play').addClass('timer__stop glyphicons-stop');
        } else {
            $('#tasks-tool [data-item-id="' + currentTrackedItem + '"] .timer__stop').removeClass('timer__stop glyphicons-stop').addClass('timer__start glyphicons-play');
        }
    }
}

$('.datepicker').datepicker({
    autoclose: true,
    format: "dd/mm/yyyy"
});

$("#time_entry_item_select").select2();

$(document).on('click', '.create-time-entry', function (e) {
    e.preventDefault();

    Bus.$emit('addTimeEntryLegacy');
    //
    // $('#timeTrackingModalForm').modal('show');
    // $('.create-entry-title').show();
    // $('#timeTrackingModalForm').find('form').addClass('create_time_entry');
    //
    // $('#timeTrackingModalForm').find('.timepicker').timepicker({
    //     showMeridian: false,
    //     minuteStep: 1
    // });
    // $('#timeTrackingModalForm').find('.timepicker').timepicker('setTime', moment().format('HH:mm'));
    //
    // $('.datepicker').datepicker('setDate', new Date());
    // $('.datepicker').datepicker('update');
});

$(document).on('click', '.update-time-entry', function (e) {
    e.preventDefault();

    $('#timeTrackingModalForm').modal('show');
    $('.update-entry-title').show();
    $('#timeTrackingModalForm').find('form').addClass('edit_time_entry').attr('data-entryid', $(this).data('entryid'));

    $('#timeTrackingModalForm').find('.timepicker').timepicker({
        showMeridian: false,
        minuteStep: 1,
        defaultTime: false
    });

    $('.datepicker').datepicker('setDate', new Date());
    $('.datepicker').datepicker('update');

    $.ajax({
        url: '/times/' + $(this).data('entryid') + '/edit',
        method: 'get'
    }).done(function (data) {
        $('#time_entry_form_name').val(data.name);
        $('#time_entry_form_category option:eq(' + data.category_id + ')').prop('selected', true)
        $('#time_entry_form_description').val(data.description);
        $('#time_entry_form_date').datepicker('setDate', moment(data.start_time).format('DD/MM/YYYY'));
        $('#time_entry_form_date').datepicker('update');
        $('#time_entry_form_start').timepicker('setTime', moment(data.start_time).format('HH:mm'));
        $('#time_entry_form_end').timepicker('setTime', moment(data.end_time).format('HH:mm'));
        $('#time_entry_form_billable').val(data.billable);
        if (data.billable == 1) {
            $('#time_entry_form_billable').prop('checked', true)
        }
        $('#time_entry_form_invoiced').val(data.invoiced);
        if (data.invoiced == 1) {
            $('#time_entry_form_invoiced').prop('checked', true)
        }
        $('#time_entry_form_delegable').val(data.delegable);
        if (data.delegable == 1) {
            $('#time_entry_form_delegable').prop('checked', true)
        }
    })
});

$('#timeTrackingModalForm').on('hidden.bs.modal', function () {
    $('#timeTrackingModalForm').find('form').removeClass('create_time_entry');
    $('#timeTrackingModalForm').find('form').removeClass('edit_time_entry');
    $('.create-entry-title').hide();
    $('.update-entry-title').hide();
    $('#time_entry_form_billable').prop('checked', false);
    $('#time_entry_form_invoiced').prop('checked', false);
    $('#time_entry_form_delegable').prop('checked', false);
    $(this).find('form').trigger("reset");
});

$(document).on('change', '#time_entry_form_billable, #time_entry_form_invoiced, #time_entry_form_delegable', function () {
    if ($(this).prop('checked')) {
        $(this).val('1');
    } else {
        $(this).val('0');
    }
});

$(document).on('submit', '.create_time_entry', function (e) {
    e.preventDefault();

    var selected_date = moment($('[name="time_entry_form_date"]').val(), 'DD/MM/YYYY'),
        start_time = moment($('[name="time_entry_form_start"]').val(), 'HH:mm'),
        end_time = moment($('[name="time_entry_form_end"]').val(), 'HH:mm'),
        difference = selected_date.diff(moment($('[name="time_entry_form_start"]').val(), 'HH:mm').startOf('day'), 'days');

    if (difference > 0) {
        start_time = start_time.add(difference, 'days').unix();
        end_time = end_time.add(difference, 'days').unix();
    } else if (difference < 0) {
        start_time = start_time.subtract(Math.abs(difference), 'days').unix();
        end_time = end_time.subtract(Math.abs(difference), 'days').unix();
    } else {
        start_time = start_time.unix();
        end_time = end_time.unix();
    }

    $.ajax({

        url: '/times',
        method: 'post',
        data: {
            name: $('[name="time_entry_form_name"]').val(),
            category_id: $('[name="time_entry_form_category"]').val(),
            description: $('[name="time_entry_form_description"]').val(),
            start_time: start_time,
            end_time: end_time,
            billable: $('[name="time_entry_form_billable"]').val(),
            invoiced: $('[name="time_entry_form_invoiced"]').val(),
            delegable: $('[name="time_entry_form_delegable"]').val()
        }

    }).done(function () {
        $('#timeTrackingModalForm').modal('hide');
        location.reload();
    });
});

$(document).on('submit', '.edit_time_entry', function (e) {
    e.preventDefault();

    var selected_date = moment($('[name="time_entry_form_date"]').val(), 'DD/MM/YYYY'),
        start_time = moment($('[name="time_entry_form_start"]').val(), 'HH:mm'),
        end_time = moment($('[name="time_entry_form_end"]').val(), 'HH:mm'),
        difference = selected_date.diff(moment($('[name="time_entry_form_start"]').val(), 'HH:mm').startOf('day'), 'days');

    console.log(selected_date);
    console.log(start_time);
    console.log(end_time);
    console.log(difference);

    if (difference > 0) {
        start_time = start_time.add(difference, 'days').unix();
        end_time = end_time.add(difference, 'days').unix();
    } else if (difference < 0) {
        start_time = start_time.subtract(Math.abs(difference), 'days').unix();
        end_time = end_time.subtract(Math.abs(difference), 'days').unix();
    } else {
        start_time = start_time.unix();
        end_time = end_time.unix();
    }

    $.ajax({

        url: '/times/' + $(this).data('entryid'),
        method: 'post',
        data: {
            _method: 'PUT',
            name: $('[name="time_entry_form_name"]').val(),
            category_id: $('[name="time_entry_form_category"]').val(),
            description: $('[name="time_entry_form_description"]').val(),
            start_time: start_time,
            end_time: end_time,
            billable: $('[name="time_entry_form_billable"]').val(),
            invoiced: $('[name="time_entry_form_invoiced"]').val(),
            delegable: $('[name="time_entry_form_delegable"]').val()
        }

    }).done(function () {
        $('#timeTrackingModalForm').modal('hide');
        location.reload();
    });
});

$('#toggle_sidemenu_r').on('click', function () {
    $('#sidebar_right').toggleClass('open');
    if ($('#sidebar_right').hasClass('open')) {
        $.ajax({
            url: '/api/users/current_task',
            method: 'post'
        }).done(function (data) {
            $.each(data, function (i, value) {
                var classes,
                    status;

                if (i == 0) {
                    classes = 'media mt30 border-none';
                } else {
                    classes = 'media border-none';
                }

                if (value.paused == 0) {
                    if (value.time_entry) {
                        status = '<i class="fa fa-circle text-green2 pr5"></i> ' + value.time_entry;
                    } else {
                        status = '<i class="fa fa-circle text-green2 pr5"></i> Not yet specified';
                    }
                } else {
                    status = '<i class="fa fa-circle text-red2 pr5"></i> Inactive';
                }

                $('.current-users-activity').append('<div class="' + classes + '"><img class="pull-left media-object thumbnail thumbnail-sm rounded" src="' + get_image_thumbnail(value.user_image, '100x100_crop') + '"><div class="media-body"><h5 class="media-heading text-white mbn">' + value.user_name + '</h5><p class="text-muted fs12">' + status + '</p></div></div>');
            });
        });
    } else {
        $('.current-users-activity').empty();
    }
});

/*$('#tasks-tool .nav-tabs').on('shown.bs.tab', function(e) {
 if(e.target.hash == '#tasks-list') {
 getFields('projects', ['id', 'name', 'description', 'image']);
 $('.dropdownSearch').val('');
 }
 });*/

/*app.BrainSocket.Event.listen('generic.event',function(msg){
 console.log(msg);
 });

 app.BrainSocket.Event.listen('app.success',function(data){
 console.log('An app success message was sent');
 console.log(data);
 });

 app.BrainSocket.Event.listen('app.error',function(data){
 console.log('An app error message was sent');
 console.log(data);
 });*/

/*$(window).on('beforeunload', function(){
 return '';
 });*/

/*
 * Get only specified fields function
 */
function getFields(resource, fields) {
    var itemList = $('.recent-' + resource + '-list'),
        resourceSingular = resource.singularize(),
        item = itemList.find('.' + resourceSingular + '.clone').clone();

    itemList.css({opacity: 0});
    $('.ajax-loader').show();

    var request = $.ajax({
        method: 'post',
        url: '/api/' + resource + '/fields',
        data: {fields: fields}
    });

    request.done(function (msg) {
        if (!$.isEmptyObject(msg)) {
            var itemOutput = [];

            itemList.find('.' + resourceSingular).not('.clone').remove();

            $.each(msg, function (index, field) {
                if (field) {
                    itemOutput[index] = item.clone();
                    var itemId = field.id;

                    $.each(field, function (i, value) {
                        if (i === 'image') {
                            if (value != '') {
                                itemOutput[index].find('.' + resourceSingular + '__' + i).attr('src', get_image_thumbnail(value, '100x100'));
                            } else {
                                itemOutput[index].find('.' + resourceSingular + '__' + i).attr('src', '/img/placeholders/default/placeholder-100x100.png');
                            }
                        } else {
                            itemOutput[index].find('.' + resourceSingular + '__' + i).text(value);
                        }

                        if (i === 'name' && resourceSingular === 'task') {
                            itemOutput[index].find('.' + resourceSingular + '__' + i).click(function() {
                                Bus.$emit('openSidebarPanel', 'task', field.id);
                            });
                        }
                    });

                    itemOutput[index].attr('data-item-id', itemId).removeClass('clone');

                    itemList.append(itemOutput[index]);
                }
            });

            checkIfTrackedItem();

        } else {
            itemList.append('<p class="empty-view">No ' + resource + ' viewed</p>');
        }

        itemList.animate({opacity: 1}, 200);
        $('.ajax-loader').hide();
    });

    /*app.BrainSocket.message('generic.event',{
     'message' : 'test'
     });*/
}

/*
 * Search for Projects in tool
 */
$('.dropdown-input.search').autocomplete({
    minChars: 3,
    zIndex: 100000,
    maxHeight: 'auto',
    type: 'POST',
    serviceUrl: '/api/projects/search',
    onSelect: function (suggestion) {
        slider.next(suggestion.data);

        $('.slide-panel-back').removeClass('hidden');
        $('.dropdown-input.search').addClass('hidden');
        $('.dropdown-input.create-task').removeClass('hidden');

        $('.dropdown-input.search').val('');
    },
    transformResult: function (response) {
        response = JSON.parse(response);
        return {
            suggestions: $.map(response, function (dataItem) {
                return {value: dataItem.name, data: dataItem.id, image: dataItem.image};
            })
        };
    },
    formatResult: function (suggestion) {
        var img = '<div class="media-object suggestion-image border border-purple bw1">';
        if (suggestion.image != null) {
            img += '<img src="/media/img/projects/' + suggestion.data + '/' + suggestion.image + '" class="br64" />';
        } else {
            img += '<img src="/media/img/projects/project_placeholder_small.png" />';
        }
        img += '</div>';
        return img + '<div class="suggestion-text" data-item-id="suggestion.data">' + suggestion.value + '</div>';
    }
});

/*
 * Slide Panel jQuery Plugin
 */
(function ($) {
    $.fn.slidePanel = function (options) {

        var settings = $.extend({
            speed: 500
        }, options);

        var methods = {
            "init": function () {
                slider.wrap('<div class="slider-container"></div>');
                $('.slider-container').css({width: width});

                slider.css({width: totalWidth}).find('> *:first-child').addClass('active');
                slides.css({width: width});

                return this;
            },
            "next": function (e) {
                var activeSlide = slider.find('.active'),
                    currentSlide = activeSlide.index(),
                    nextSlide = currentSlide + 1;

                if (currentSlide !== slidesNumber) {
                    activeSlide.removeClass('active');
                    slides.animate({left: -width}, settings.speed).eq(nextSlide).addClass('active');

                    var nextSlideElement = slides.eq(nextSlide);
                }

                if (options.onNextPanel !== undefined) {
                    options.onNextPanel(nextSlideElement, e);
                }

                return this;
            },
            "prev": function (e) {
                var activeSlide = slider.find('.active'),
                    currentSlide = activeSlide.index(),
                    prevSlide = currentSlide - 1;

                if (currentSlide !== 0) {
                    activeSlide.removeClass('active');
                    slides.animate({left: 0}, settings.speed).eq(prevSlide).addClass('active');

                    var prevSlideElement = slides.eq(prevSlide);
                }

                if (options.onPreviousPanel !== undefined) {
                    options.onPreviousPanel(prevSlideElement, e);
                }

                return this;
            },
            "currentIndex": function () {
                var index = $(this).find('.active').index();

                return index;
            }
        };

        var data = $(this).data('initialised');

        var slider = $(this),
            slides = slider.find('> *'),
            slidesNumber = slides.length,
            width,
            totalWidth;

        if (!data) {
            width = slider.width();
            totalWidth = width * slidesNumber;

            methods.init();
            $(this).data('initialised', true);
        } else {
            width = slides.innerWidth();
        }

        return methods;
    }
}(jQuery));

$(document).on('click', '.comment-action .update', function () {
    var item = $(this).parents('.timeline-item');
    var commentText = item.find('.comment-text');
    var commentEdit = item.find('.comment-edit');
    commentText.hide();
    commentEdit.show();
    item.find('.btn-success').on('click', function () {
        var commentValue = commentEdit.find('textarea').val();
        var data = {
            pk: item.data('id'),
            name: 'comment',
            value: commentValue
        };

        $.ajax({
            type: 'PUT',
            url: '/comments/' + item.data('id'),
            data: data
        }).done(function () {
            commentText.html(nl2br(commentValue));
            commentText.show();
            commentEdit.hide();
        }).fail(function (msg) {
            alert("Not successfully: " + msg);
        });
        return false;
    });
    return false;
});

$(document).on('ajax:success', '.comment-action .remove', function () {
    $(this).parents('.timeline-item').remove();
});

$(document).on('click', '.set-status', function () {
    $(this).parents('li').click();
});

function nl2br(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

